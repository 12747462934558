import {
  Fab,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  Paper,
} from "@mui/material";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { useUserBudgetList } from "../../data";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { Home } from "@mui/icons-material";

export const LeftPanel = () => {
  const { data } = useUserBudgetList();
  const navigate = useNavigate();
  const handleBudgetClick = (id: string | undefined) => {
    navigate(`/budgets/${id}`);
  };
  return (
    <Paper>
      <List
        component="nav"
        aria-labelledby="nested-list-subheader"
        subheader={
          <ListSubheader component="div" id="nested-list-subheader">
            Available Budgets
          </ListSubheader>
        }
      >
        <ListItem component="div" disablePadding>
          <ListItemButton onClick={() => navigate("/budgets")}>
            <ListItemIcon>
              <Home color="primary" />
            </ListItemIcon>
            <ListItemText primary="Available Budgets" color={"primary"} />
          </ListItemButton>
          <Fab color={"secondary"} onClick={() => navigate("/budgets/new")}>
            <AddCircleOutlineIcon />
          </Fab>
        </ListItem>
        {data?.map((budget) => (
          <ListItemButton
            key={budget.id}
            onClick={() => handleBudgetClick(budget.id)}
          >
            <ListItemIcon>
              <AttachMoneyIcon />
            </ListItemIcon>
            <ListItemText
              primary={budget.name}
              secondary={moment(budget.createdAt).format("LL")}
            />
          </ListItemButton>
        ))}
      </List>
    </Paper>
  );
};
