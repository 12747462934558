import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  FormControl,
  FormHelperText,
  Input,
  InputLabel,
  MenuItem,
  Select,
  Stack,
} from "@mui/material";
import moment from "moment";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Cancel";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { IBudget } from "../../models";
import { useCreateBudget } from "../../data/useCreateBudget";

//array of all month names
const months = [
  { id: 0, name: "January" },
  { id: 1, name: "February" },
  { id: 2, name: "March" },
  { id: 3, name: "April" },
  { id: 4, name: "May" },
  { id: 5, name: "June" },
  { id: 6, name: "July" },
  { id: 7, name: "August" },
  { id: 8, name: "September" },
  { id: 9, name: "October" },
  { id: 10, name: "November" },
  { id: 11, name: "December" },
];

export const CreateNewBudget = () => {
  const currentYear = moment().year();
  const years = [
    currentYear - 1,
    currentYear,
    currentYear + 1,
    currentYear + 2,
  ];
  const [budgetYear, setBudgetYear] = useState(currentYear);
  const [budgetMonth, setBudgetMonth] = useState(moment().month());
  const [budgetName, setBudgetName] = useState("");
  const navigate = useNavigate();
  const { mutate: createBudget, isError, error } = useCreateBudget();
  const createNewBudget = () => {
    const budget: IBudget = {
      name: budgetName,
      year: budgetYear,
      month: budgetMonth,
      createdAt: new Date(),
      updatedAt: new Date(),
    };
    createBudget(budget);
  };
  return (
    <Card>
      <CardHeader title="Create new Budget" subheader={moment().format("LL")} />
      <CardContent>
        <Stack>
          {isError && <FormHelperText error>{error}</FormHelperText>}
          <FormControl fullWidth variant={"standard"}>
            <InputLabel htmlFor="budget-year">Budget Year</InputLabel>
            <Select
              id="budget-year"
              aria-describedby="budget year"
              value={budgetYear}
              onChange={(nv) => setBudgetYear(nv.target.value as number)}
            >
              {years.map((year) => (
                <MenuItem key={year} value={year}>
                  {year}
                </MenuItem>
              ))}
            </Select>
            <FormHelperText>Select a year for the budget.</FormHelperText>
          </FormControl>
          <FormControl fullWidth variant={"standard"}>
            <InputLabel htmlFor="budget-month">Budget Month</InputLabel>
            <Select
              id="budget-month"
              aria-describedby="budget month"
              value={budgetMonth}
              onChange={(nv) => setBudgetMonth(nv.target.value as number)}
            >
              {months.map((m) => (
                <MenuItem key={m.id} value={m.id}>
                  {m.name}
                </MenuItem>
              ))}
            </Select>
            <FormHelperText>Select a month for the budget.</FormHelperText>
          </FormControl>
          <FormControl fullWidth variant={"standard"}>
            <InputLabel htmlFor="budget-name">Budget Name</InputLabel>
            <Input
              id="budget-name"
              type="text"
              aria-describedby="budget name"
              value={budgetName}
              onChange={(nv) => setBudgetName(nv.target.value as string)}
            />
            <FormHelperText>Input a name for the budget.</FormHelperText>
          </FormControl>
        </Stack>
      </CardContent>
      <CardActions>
        <Button
          aria-label="create a new budget"
          startIcon={<SaveIcon />}
          variant="contained"
          onClick={createNewBudget}
        >
          SAVE
        </Button>
        <Button
          aria-label="create a new budget"
          startIcon={<CancelIcon />}
          variant="contained"
          color={"secondary"}
          onClick={() => navigate("/budgets")}
        >
          CANCEL
        </Button>
      </CardActions>
    </Card>
  );
};
