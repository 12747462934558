import {
  Button,
  Grid,
  Paper,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { useState } from "react";
import { useAuthContext } from "../utils";

export const Login = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { signIn, error } = useAuthContext();
  const returnUrl = (location.state as any)?.returnUrl || "/";
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const performLogin = () => {
    signIn(email, password).then(() => navigate(returnUrl, { replace: true }));
  };
  return (
    <Grid container padding={1}>
      <Grid item xs={12} md={4}>
        <Paper elevation={2}>
          <Stack spacing={4} padding={2}>
            <Typography m="normal" variant="h5" textAlign="center">
              LOGIN
            </Typography>
            <TextField
              label="Email"
              type="email"
              margin="normal"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <TextField
              label="Password"
              type="password"
              margin="normal"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <Button color="primary" variant="contained" onClick={performLogin}>
              Login
            </Button>
            {error && <Typography>{error.message}</Typography>}
          </Stack>
        </Paper>
      </Grid>
    </Grid>
  );
};
