import { Route, Routes } from "react-router-dom";
import { BudgetLayout, DashboardLayout, StandardLayout } from "../layout";
import {
  About,
  Budget,
  BudgetHome,
  CreateNewBudget,
  Dashboard,
  Home,
  Login,
  PageNotFound,
} from "../pages";

export const AppRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<StandardLayout />}>
        <Route index element={<Home />} />
        <Route path="about" element={<About />} />
        <Route path="login" element={<Login />} />
        <Route path="dashboard" element={<DashboardLayout />}>
          <Route index element={<Dashboard />} />
        </Route>
        <Route path="budgets" element={<BudgetLayout />}>
          <Route index element={<BudgetHome />} />
          <Route path="new" element={<CreateNewBudget />} />
          <Route path=":id" element={<Budget />} />
        </Route>
        <Route path="*" element={<PageNotFound />} />
      </Route>
    </Routes>
  );
};
