import { Paper } from "@mui/material";
import { AuthContextProvider, ThemeSwitchProvider } from "../utils";
import { AppRoutes } from "./AppRoutes";
import { QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { queryClient } from "../data";

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <ThemeSwitchProvider>
        <AuthContextProvider>
          <Paper elevation={0} square>
            <AppRoutes />
          </Paper>
        </AuthContextProvider>
      </ThemeSwitchProvider>
      {process.env.NODE_ENV === "development" && (
        <ReactQueryDevtools position="bottom-right" />
      )}
    </QueryClientProvider>
  );
}

export default App;
