import { createContext, ReactNode, useContext, useMemo, useState } from "react";
import { createTheme, PaletteMode, ThemeProvider } from "@mui/material";
import { getDesignTokens } from "./theme";

export interface ThemeContextProps {
  themeMode: PaletteMode;
  setThemeMode: (themeMode: PaletteMode) => void;
}

export type ThemeSwitchProviderProps = {
  children: ReactNode;
};

const themeContext = createContext<ThemeContextProps>({
  themeMode: "light",
  setThemeMode: () => {},
});

// A context provider for MUI theme switching
export const ThemeSwitchProvider = ({ children }: ThemeSwitchProviderProps) => {
  const [themeMode, setThemeMode] = useState<PaletteMode>("light");

  // Update the theme only if the mode changes
  const theme = useMemo(
    () => createTheme(getDesignTokens(themeMode)),
    [themeMode]
  );
  return (
    <themeContext.Provider value={{ themeMode, setThemeMode }}>
      <ThemeProvider theme={theme}>{children}</ThemeProvider>
    </themeContext.Provider>
  );
};

export const useThemeContext = () => {
  const context = useContext(themeContext);
  if (context === undefined) {
    throw new Error(
      "useThemeContext must be used within a ThemeSwitchProvider"
    );
  }
  return context;
};
