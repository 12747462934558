import { Navigate, Outlet, useLocation } from "react-router-dom";
import { useAuthContext } from "../utils";
import { Grid } from "@mui/material";
import { LeftPanel } from "../pages/budget/LeftPanel";

export const BudgetLayout = () => {
  const { user } = useAuthContext();
  const location = useLocation();
  if (!user) {
    return <Navigate to={"/login"} replace state={{ returnUrl: location }} />;
  }
  return (
    <Grid container padding={2} spacing={2}>
      <Grid item xs={12} md={4}>
        <LeftPanel />
      </Grid>
      <Grid item xs={12} md={8}>
        <Outlet />
      </Grid>
    </Grid>
  );
};
