import { Navigate, Outlet, useLocation } from "react-router-dom";
import { useAuthContext } from "../utils";

export const DashboardLayout = () => {
  const { user } = useAuthContext();
  const location = useLocation();
  if (!user) {
    return <Navigate to={"/login"} replace state={{ returnUrl: location }} />;
  }
  return (
    <div>
      <h1>Dashboard Layout</h1>
      <Outlet />
    </div>
  );
};
