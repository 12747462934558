import { Link, useLocation, useNavigate } from "react-router-dom";
import { AppBar, Box, Button, IconButton, Toolbar } from "@mui/material";
import { Brightness4, Brightness5, Home } from "@mui/icons-material";
import { useAuthContext, useThemeContext } from "../utils";

export const Navbar = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { setThemeMode, themeMode } = useThemeContext();
  const { user, signOut } = useAuthContext();
  const navigateHome = () => {
    navigate("/");
  };
  const performSignOut = () => {
    signOut().then(() => {
      navigate("/");
    });
  };
  const redirectLogin = () => {
    navigate("/login", { state: { returnUrl: location }, replace: true });
  };
  return (
    <AppBar position="static">
      <Toolbar>
        <IconButton
          size="large"
          edge="start"
          color="inherit"
          aria-label="menu"
          sx={{ mr: 2 }}
          onClick={navigateHome}
        >
          <Home />
        </IconButton>
        <Button color="inherit" component={Link} to="/budgets">
          Budgets
        </Button>
        <Button color="inherit" component={Link} to="/dashboard">
          Dashboard
        </Button>
        <Button color="inherit" component={Link} to="/about">
          About
        </Button>
        <Box sx={{ flexGrow: 1 }} />
        {themeMode === "light" ? (
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            onClick={() => setThemeMode("dark")}
          >
            <Brightness4 />
          </IconButton>
        ) : (
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            onClick={() => setThemeMode("light")}
          >
            <Brightness5 />
          </IconButton>
        )}
        {user ? (
          <Button color="inherit" onClick={performSignOut}>
            Logout
          </Button>
        ) : (
          <Button color="inherit" onClick={redirectLogin}>
            Login
          </Button>
        )}
      </Toolbar>
    </AppBar>
  );
};
