import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from "react";
import { analytics, auth } from "./firebase";
import {
  signInWithEmailAndPassword,
  signOut as fbSignOut,
  UserCredential,
} from "firebase/auth";
import { logEvent } from "firebase/analytics";

export interface IAuthContext {
  user: UserCredential | null;
  error: Error | null;
  signIn: (email: string, password: string) => Promise<void>;
  signOut: () => Promise<void>;
}

const AuthContext = createContext<IAuthContext>({
  user: null,
  error: null,
  signIn: () => Promise.resolve(),
  signOut: () => Promise.resolve(),
});

export interface AuthContextProps {
  children: ReactNode;
}

export const AuthContextProvider = ({ children }: AuthContextProps) => {
  const [user, setUser] = useState<UserCredential | null>(null);
  const [error, setError] = useState<Error | null>(null);
  useEffect(() => {
    const storedUser = sessionStorage.getItem("user");
    if (storedUser) {
      try {
        setUser(JSON.parse(storedUser));
      } catch (e) {
        console.error(e);
      }
    }
  }, []);
  useEffect(() => {
    if (user) {
      sessionStorage.setItem("user", JSON.stringify(user));
    } else {
      sessionStorage.removeItem("user");
    }
  }, [user]);
  const signIn = (email: string, password: string) => {
    return signInWithEmailAndPassword(auth, email, password)
      .then((user) => {
        setUser(user);
        logEvent(analytics, "sign_in", {
          method: "email_and_password",
          success: true,
          user_id: user.user.uid,
        });
      })
      .catch((error) => {
        setError(error);
        logEvent(analytics, "sign_in", {
          method: "email_and_password",
          success: false,
        });
      });
  };
  const signOut = () => {
    return fbSignOut(auth)
      .then(() => {
        setUser(null);
        logEvent(analytics, "sign_out", {
          method: "user_action",
        });
      })
      .catch((error) => {
        setError(error);
      });
  };
  return (
    <AuthContext.Provider value={{ user, error, signIn, signOut }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuthContext = () => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error("useAuthContext must be used within a AuthContextProvider");
  }
  return context;
};
