import { collection, getDocs } from "firebase/firestore";
import { firestore, useAuthContext } from "../utils";
import { useQuery } from "react-query";
import { IBudget } from "../models";

const fetchUserBudgetList = (email: string) => {
  const budgetCollection = collection(firestore, "users", email, "budgets");
  return getDocs(budgetCollection);
};

export const useUserBudgetList = () => {
  const { user } = useAuthContext();
  const email = user?.user.email as string;
  return useQuery("userBudgetList", () => fetchUserBudgetList(email), {
    select: (data) => {
      if (data.empty) return [];
      return data.docs.map((doc) => {
        const bdj = doc.data() as IBudget;
        bdj.id = doc.id;
        bdj.createdAt = doc.get("createdAt")?.toDate();
        bdj.updatedAt = doc.get("updatedAt")?.toDate();
        return bdj;
      });
    },
    staleTime: 60 * 1000,
  });
};
