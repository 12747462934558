import { Outlet, useLocation } from "react-router-dom";
import { Navbar } from "../components";
import { Box, Paper } from "@mui/material";
import { useEffect } from "react";
import { logEvent } from "firebase/analytics";
import { analytics } from "../utils";

export const StandardLayout = () => {
  const location = useLocation();
  useEffect(() => {
    logEvent(analytics, "page_view", { page_path: location.pathname });
  }, [location]);
  return (
    <Box sx={{ display: "flex", flexDirection: "column", height: "100vh" }}>
      <Navbar />
      <Paper sx={{ flexGrow: 1 }} square>
        <Outlet />
      </Paper>
    </Box>
  );
};
