import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyBwy-rWtAdmFkyxF-pRkhyo-qQAb6cpNU4",
  authDomain: "my-finance-a9ba3.firebaseapp.com",
  projectId: "my-finance-a9ba3",
  storageBucket: "my-finance-a9ba3.appspot.com",
  messagingSenderId: "528175839955",
  appId: "1:528175839955:web:6bc72c62ec564154a3b226",
  measurementId: "G-H7D54NSK0Y",
};
export const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const firestore = getFirestore(app);
export const analytics = getAnalytics(app);
