import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Typography,
} from "@mui/material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import moment from "moment";
import { useNavigate } from "react-router-dom";

export const BudgetHome = () => {
  const navigate = useNavigate();
  return (
    <Card>
      <CardHeader title="Your Budget Home" subheader={moment().format("LL")} />
      <CardContent>
        <Typography variant={"body2"}>
          Please Select a Budget or create one
        </Typography>
      </CardContent>
      <CardActions disableSpacing>
        <Button
          aria-label="create a new budget"
          startIcon={<AddCircleOutlineIcon />}
          variant="contained"
          onClick={() => navigate("/budgets/new")}
        >
          CREATE BUDGET
        </Button>
      </CardActions>
    </Card>
  );
};
