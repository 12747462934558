import { IBudget } from "../models";
import {
  addDoc,
  collection,
  DocumentData,
  DocumentReference,
} from "firebase/firestore";
import { firestore, useAuthContext } from "../utils";
import { useMutation, useQueryClient } from "react-query";

export const useCreateBudget = (
  onSuccess?: (docRef: DocumentReference<DocumentData>) => void
) => {
  const { user } = useAuthContext();
  const queryClient = useQueryClient();
  if (!(user && user.user.email)) {
    throw new Error("User is not logged in");
  }
  const email = user.user.email;
  return useMutation<DocumentReference<DocumentData>, Error, IBudget>(
    (budget: IBudget) => {
      const budgetCollection = collection(firestore, "users", email, "budgets");
      return addDoc(budgetCollection, budget);
    },
    {
      onSuccess: (data) => {
        if (onSuccess) {
          onSuccess(data);
        }
        queryClient.invalidateQueries("userBudgetList").then();
      },
    }
  );
};
